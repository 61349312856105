import {
  type CO2ByArticleGroup,
  type CO2Trend,
  Country,
  type GraphTableDataI,
  type GraphTableDataRowI,
  type WasteHierarchy,
} from '@stenarecycling/customer-portal-types';

import {
  formatNumberToKgUnit,
  formatNumberToLocale,
  makeLabels,
  makeMonthLabels,
} from '../../utils';

export const makeTableDataEnvironmentWasteHierarchy = (
  data: WasteHierarchy,
  label: string,
  t: (key: string) => string,
  options?: Record<string, string | number>,
  disableTooltip?: boolean,
): GraphTableDataI => {
  let { landfillKg, incinerationKg, bioEnergyKg, recyclingKg, reuseKg, quantityKg, otherKg } = data;

  const { unspecifiedKg } = data;

  if (
    landfillKg === null &&
    incinerationKg === null &&
    bioEnergyKg === null &&
    recyclingKg === null &&
    reuseKg === null &&
    quantityKg === null &&
    (otherKg === null || otherKg === 0) // otherKg is 0 for sweden and norway
  ) {
    throw Error('No data for waste hierarchy');
  }

  // make sure we don't have any null values
  landfillKg ??= 0;
  incinerationKg ??= 0;
  bioEnergyKg ??= 0;
  recyclingKg ??= 0;
  reuseKg ??= 0;
  quantityKg ??= 0;
  otherKg ??= 0;

  const subset = [
    { landfillKg },
    { incinerationKg },
    { bioEnergyKg },
    { recyclingKg },
    { reuseKg },
    { unspecifiedKg },
  ] as unknown as WasteHierarchy[];

  const shouldAddOther = options?.country === `${Country.DENMARK}`;

  if (shouldAddOther) {
    subset.splice(5, 0, { otherKg } as WasteHierarchy);
  }

  console.log('subset', subset);

  const labels = makeLabels(subset as unknown as [Record<string, number>], true, t);

  const rowsValuesKg = subset.map((column) => Object.values(column)[0]);
  const totalKg = quantityKg;

  // Get percentages
  const rowValuesPercentage = [
    landfillKg / totalKg,
    incinerationKg / totalKg,
    bioEnergyKg / totalKg,
    recyclingKg / totalKg,
    reuseKg / totalKg,
    unspecifiedKg / totalKg,
  ];

  if (shouldAddOther) {
    rowValuesPercentage.splice(5, 0, otherKg / totalKg);
  }

  if (rowsValuesKg.every((val: unknown) => val === null || val === '-')) {
    return {
      columns: [],
      rows: [],
      label,
    };
  }

  const wasteMinRow = getWasteMinimizationRow(t, disableTooltip);
  const naValue = 'N/A';

  // combine labels, kg and percentage values into one array
  const valuesRows: GraphTableDataRowI[] = labels.map((label, index) => {
    const value = rowsValuesKg[index];
    const valueFormatted = value ? formatNumberToKgUnit(value) : '0';

    return [
      { value: label },
      {
        value: valueFormatted,
      },
      {
        value:
          totalKg === 0
            ? naValue
            : formatNumberToLocale(rowValuesPercentage[index], {
                style: 'percent',
              }),
      },
    ];
  });

  const rows = [wasteMinRow, ...valuesRows];

  const columns = [
    t('analysis.graph.table.environment.wasteHierarchy.column.recycleRate'),
    'kg',
    t('analysisPage.tooltipLabel.percent'),
  ];

  return {
    columns,
    rows,
    label,
  };
};

const getWasteMinimizationRow = (t: (key: string) => string, disableTooltip?: boolean) => {
  const wasteMinText = t('analysis.graph.table.wasteminimization');
  const wasteMinRow = [
    { value: t('graph.column.wasteMinimization') },
    { value: '' },
    {
      value: disableTooltip ? wasteMinText : '',
      tooltipText: disableTooltip ? '' : wasteMinText,
    },
  ];

  return wasteMinRow;
};

export const makeTableDataEnvironmentEmissionTrend = (
  data: CO2Trend[],
  label: string,
  t: (key: string) => string,
): GraphTableDataI => {
  const months = makeMonthLabels(data, t);
  const rows = data.map((item, index) => [
    { value: months[index] },
    {
      value: formatNumberToKgUnit(item.cO2Kg),
    },
    {
      value: formatNumberToLocale(item.kgCO2ePerKgMaterial, {
        style: 'unit',
        unit: 'kilogram',
        minimumFractionDigits: 3,
        maximumFractionDigits: 3,
        removeTrailingZeros: false,
      }),
    },
  ]);

  return {
    columns: [
      t('analysis.graph.table.environment.c02.column.article.group'),
      t('analysis.graph.table.environment.c02.column.kgco2'),
      t('analysis.graph.table.environment.c02.column.kgco2e'),
    ],
    rows,
    label,
  };
};
export const makeTableDataEnvironmentC02Savings = (
  data: CO2ByArticleGroup[],
  label: string,
  t: (key: string) => string,
): GraphTableDataI => {
  const rows = data.map((item) => [
    {
      value: item.articleGroupDescription,
    },
    {
      value: formatNumberToKgUnit(item.cO2kg),
    },
    {
      value: formatNumberToLocale(item.kgCO2ePerKgMaterial, {
        style: 'unit',
        unit: 'kilogram',
        minimumFractionDigits: 3,
        maximumFractionDigits: 3,
        removeTrailingZeros: false,
      }),
    },
  ]);

  return {
    rows,
    label,
    columns: [
      t('analysis.graph.table.environment.c02.column.article.group'),
      t('analysis.graph.table.environment.c02.column.kgco2'),
      t('analysis.graph.table.environment.c02.column.kgco2e'),
    ],
  };
};
